import { reactive } from 'vue';

const sidebarProps = reactive({
  isOpened: true,
  changeState() {
    this.isOpened = !this.isOpened;
  },
});

export default sidebarProps;
