<template>
  <div id="supportModal">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <button class="delete" @click="$emit('close')"></button>
        <div class="modal-card-content">
          <h1 class="support-header"><b>Need Help? Have a Question?</b></h1>
          <p>
            Reach out to your GiveCard contact <br />
            or email support@givecard.io.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#supportModal .modal-card {
  border-radius: 12px;
  background-color: white;
}
#supportModal .modal-card-content {
  padding: 20px;
  padding-left: 15%;
  padding-right: 15%;
  background-color: white;
  margin-bottom: 30px;
}

#supportModal .support-header {
  margin-top: 0 !important;
  text-align: center !important;
  font-size: 30px !important;
  margin-bottom: 0px;
}

#supportModal .delete {
  float: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: auto;
}
</style>
