<template>
  <section
    class="layout"
    :class="{ collapsed: !sidebarProps.isOpened, noNavBar: !shouldShowNavbar }"
    v-if="isRouteReady"
  >
    <div class="sidebar">
      <NavBar :inPaddedColumn="true" v-if="shouldShowNavbar" />
    </div>
    <div class="view-content">
      <router-view />
    </div>
  </section>
</template>

<style>
@media screen and (min-width: 1027px) {
  .layout {
    display: grid;
    grid-template-columns: 200px auto;
    transition: grid-template-columns 0.3s ease-in-out;
    gap: 20px;
  }
  .layout.collapsed {
    grid-template-columns: 60px auto;
  }
  .layout.noNavBar {
    grid-template-columns: 0 auto;
    gap: 0;
  }
}

@media (max-width: 1026px) {
  .layout {
    display: block;
  }
  .view-content {
    padding-bottom: 100px;
  }
}

@media (max-width: 1150px) {
  .layout {
    padding-right: 15px;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #363636;
}

:root {
  --purplish-white: #f3eeff; /* primary */
  --midnight-blue: #606bfc; /* secondary */
  --midnight-blue-light: #afb5fd;
  --almost-white: #fbf9ff; /* background */
  --level-three-grey: #979599;
  --level-two-grey: #e2e0e4;
  --level-one-grey: #f0f0f0;
  --pitch-black: #000000; /* text */
  --muted-black: #363636;
  /* accents */
  --spell-violet: #a888fc;
  --forbidden-peach: #f5acfe;
  --toad-green: #00ea96;
  --suspicious-turquoise: #69d6d6;
  --warning: #f14668;
  --warning-light: #fedada;
  --success: #00c77f;
  /* custom colors */
  --custom-primary: #e2e0e4;
  --custom-secondary: black;
  --custom-secondary-light: grey;
  --custom-accent: #2525f8;
  --white-grey-purple: #faf9fc;
  --disabled-input-field: whitesmoke;
  --disabled-input-text: #7a7a7a;
}

@font-face {
  font-family: Fenul;
  src: url('~@/assets/fonts/FenulStandard-Regular.ttf');
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: --var(midnight-blue);
}

h1 {
  font-size: 25px;
  color: var(--pitch-black);
}

h2 {
  font-weight: bold;
  font-size: 22px;
  color: var(--pitch-black);
}

h4 {
  padding-left: 50px;
  padding-right: 50px;
  color: var(--pitch-black);
}

p {
  color: var(--pitch-black);
}

a {
  color: var(--midnight-blue);
}

input[type='checkbox'] {
  accent-color: var(--midnight-blue);
}

input[type='checkbox']:checked {
  background-color: var(--midnight-blue);
}

input[type='radio']:checked {
  background-color: var(--midnight-blue);
}

input.input:focus {
  box-shadow: 0 0.0625em 0.125em var(--midnight-blue);
  border-color: var(--midnight-blue-light);
}

header {
  margin-top: 50px;
  margin-bottom: 50px;
}

.breadcrumb a {
  color: var(--midnight-blue);
}

.is-icon {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.disabled-icon {
  color: var(--level-three-grey);
}

.button:hover {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.button.is-primary {
  background-color: var(--midnight-blue);
  border: 0px;
}

.is-primary {
  --n-border-hover: 0px !important;
}

.button.is-primary:hover,
.n-button--primary-type:hover {
  color: var(--pitch-black);
  border-color: transparent !important;
  background-color: var(--midnight-blue-light);
  --n-color-hover: var(--midnight-blue-light) !important;
  --n-text-color-hover: var(--pitch-black) !important;
}

.button.is-primary:focus,
.n-button--primary-type:focus {
  background-color: var(--midnight-blue-light);
  border-color: var(--midnight-blue);
  --n-color-pressed: var(--midnight-blue-light) !important;
  --n-color-focus: var(--midnight-blue-light) !important;
  --n-text-color-focus: var(--pitch-black) !important;
  --n-border-pressed: var(--midnight-blue) !important;
  --n-border-focus: var(--midnight-blue) !important;
  --n-ripple-color: var(--midnight-blue) !important;
  box-shadow: 0 0 0 1px var(--midnight-blue);
}

.button.is-primary:focus:not(:active) {
  box-shadow: none;
}

.button.is-ghost {
  box-shadow: none;
  color: var(--pitch-black);
}

.button.is-ghost:focus {
  box-shadow: none;
}

.button.is-primary[disabled] {
  background-color: var(--level-three-grey);
}

.button.is-secondary {
  color: var(--pitch-black);
  border-color: var(--midnight-blue);
  background-color: var(--almost-white);
}

.button.is-secondary:hover {
  color: var(--midnight-blue);
  border-color: var(--midnight-blue-light);
  background-color: var(--purplish-white);
}

.button.is-secondary:focus {
  color: var(--midnight-blue);
  border-color: var(--midnight-blue);
}

.button.is-black-outlined {
  border: 1px solid var(--pitch-black);
  color: var(--pitch-black);
}

.button.is-black-outlined:hover,
.button.is-grey-outlined:hover {
  background-color: var(--purplish-white);
  color: var(--pitch-black);
}

.n-button.is-black-outlined,
.n-button.is-grey-outlined {
  --n-border-hover: 0px !important;
  --n-border: 0px transparent !important;
}

.is-outlined-secondary {
  border: 1px solid var(--midnight-blue);
  color: var(--pitch-black);
}

.button.is-green-outlined {
  outline: 1px solid var(--midnight-blue);
  border: none;
}

.button.is-grey-outlined {
  border: 1px solid var(--level-two-grey);
  color: var(--pitch-black);
}

.button.is-subtle {
  background-color: transparent;
  border: 0px;
  color: var(--pitch-black);
  box-shadow: none;
}

.button.is-subtle:hover {
  background-color: transparent;
  border: 0px;
  color: var(--pitch-black);
  text-decoration: underline;
  box-shadow: none;
}

.button.is-subtle:focus {
  background-color: transparent;
  border: 0px;
  color: var(--midnight-blue);
  box-shadow: none;
}

.select.is-rounded select {
  border-color: var(--midnight-blue);
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: var(--midnight-blue);
}

.hero.is-primary {
  background-color: lightslategray;
}

.is-bold {
  font-weight: bold;
}

.is-error,
.is-warning {
  color: #e06872;
}

.is-info {
  color: var(--midnight-blue) !important;
}

.is-left {
  text-align: left;
}

.is-centered {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.is-right {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.disabled {
  pointer-events: none;
}

.padding-20px {
  padding-top: 20px;
}

.padding-40px {
  padding-top: 40px;
}

.right-align {
  text-align: right;
}

.modal-icons-medium {
  margin-top: 10px;
  font-size: 90px;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.7);
}

.modal-card {
  border-radius: 12px;
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  min-height: 100px;
  max-height: 600px;
}

.centered-large-icon {
  min-width: 200px;
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
}

.modal-card-head {
  display: block;
  padding: 0;
}

.modal-header {
  padding: 20px;
  text-align: left;
}

.modal-card-foot {
  border-top: none;
  background-color: white;
  justify-content: flex-end;
}

.delete {
  float: right;
  margin: 10px;
}

.modal-break {
  margin: 0;
}

@media (max-width: 1026px) {
  .modal-card {
    margin-top: 5px !important;
    max-height: 80vh;
  }
}

.large-font {
  font-size: large;
}

.medium-font {
  font-size: medium;
}

.small-font {
  font-size: small;
}

.x-small-font {
  font-size: x-small;
}

.modal-status-screen {
  text-align: center;
  margin-top: 40px;
  padding: 0px 20px 20px 20px;
}

.is-black-text {
  color: var(--pitch-black) !important;
}

.is-grey-text {
  color: var(--level-three-grey) !important;
}

.no-border {
  border: 0px !important;
}
/* N Switch */
.n-switch {
  --n-rail-color-active: var(--midnight-blue) !important;
  --n-box-shadow-focus: 0 0 0 2px var(--level-two-grey) !important;
}

/* N Button */

.n-button {
  --n-border-hover: 0px !important;
  --n-border-radius: 3px !important;
}

.n-button.is-primary {
  --n-border-hover: 0px !important;
  --n-ripple-color: var(--midnight-blue) !important;
  --n-border-focus: var(--midnight-blue) !important;
}

.n-button.is-secondary {
  --n-border-hover: 0px !important;
}

.n-button--primary-type {
  --n-border-radius: 3px;
  --n-border-hover: 0px !important;
  --n-border: 0px !important;
  --n-color: var(--midnight-blue) !important;
  --n-border-focus: var(--midnight-blue) !important;
}

.n-button--primary-type:hover {
  --n-border-radius: 3px;
  --n-border-hover: 0px !important;
  --n-border: 0px !important;
  --n-color: var(--midnight-blue) !important;
}

.n-button--default-type {
  --n-color: #0000 !important;
  --n-border: 1px solid rgb(224, 224, 230);
  --n-text-color-hover: var(--midnight-blue) !important;
  --n-text-color-focus: var(--midnight-blue) !important;
  --n-text-color-pressed: var(--midnight-blue) !important;
  --n-border-pressed: var(--midnight-blue) !important;
  --n-border-focus: var(--midnight-blue) !important;
  --n-border-hover: 1px solid var(--midnight-blue) !important;
  --n-ripple-color: var(--midnight-blue) !important;
}

/* N Dropdown Select */
.n-base-selection {
  --n-border-radius: 1px;
  --n-border-hover: var(--midnight-blue) !important;
  --n-border-focus: var(--midnight-blue) !important;
  --n-border-active: var(--midnight-blue) !important;
  --n-text-color: var(--level-three-grey) !important;
  --n-caret-color: var(--midnight-blue) !important;
  --n-box-shadow-active: 0 0 0 1px var(--midnight-blue) !important;
}

.n-base-select-menu.n-base-select-menu--multiple.n-select-menu {
  --n-option-text-color-active: var(--midnight-blue) !important;
  --n-option-check-color: var(--midnight-blue) !important;
}

/* N Date Picker */
.n-date-panel-date {
  --n-item-color-active: var(--midnight-blue) !important;
  --n-color-disabled: var(--level-three-grey) !important;
  --n-item-color-included: var(--purplish-white) !important;
}

/* N Time Picker */
.n-time-picker-panel {
  --n-item-color-active: var(--midnight-blue) !important;
  --n-item-text-color-active: var(--midnight-blue) !important;
}

.n-date-panel .n-button {
  --n-color-disabled: var(--level-three-grey) !important;
  --n-border-disabled: var(--level-three-grey) !important;
}

/* N Input */
.n-input {
  --n-border-focus: var(--midnight-blue) !important;
  --n-border-hover: var(--midnight-blue) !important;
  --n-box-shadow-focus: var(--midnight-blue-light) !important;
}

/* N Checkbox */
.n-checkbox {
  --n-border-checked: var(--midnight-blue) !important;
  --n-border-focus: var(--midnight-blue) !important;
  --n-color-checked: var(--midnight-blue) !important;
  --n-box-shadow-focus: 0 0 0 1px var(--midnight-blue) !important;
}

/* N Pagination */
.n-pagination {
  --n-item-border-active: 1px solid var(--midnight-blue) !important;
  --n-item-text-color-active: var(--midnight-blue) !important;
}

/* N Timeline */
.n-timeline-item.n-timeline-item--success-type {
  --n-icon-color: var(--midnight-blue) !important;
  --n-circle-border: 2px solid var(--midnight-blue-light) !important;
}
.n-base-select-menu .n-base-select-option.n-base-select-option--selected {
  color: var(--midnight-blue) !important;
}

.n-timeline-item.n-timeline-item--error-type {
  --n-icon-color: var(--warning) !important;
  --n-circle-border: 2px solid var(--warning) !important;
}

/* N Tooltip */
.n-tooltip {
  background-color: white !important;
  color: var(--midnight-blue);
}

.form-error-message {
  color: var(--warning);
  font-size: 12px;
}

.required {
  color: var(--warning);
}
.router-link-exact-active .links-name,
.router-link-exact-active i {
  color: #3b3b3b !important;
  font-weight: bold !important;
}
</style>

<script setup>
import { watch, onMounted, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import 'bulma';
import { useRoute, useRouter } from 'vue-router';
import NavBar from './components/NavBar.vue';
import sidebarProps from './composables/useSidenav';

const { locale } = useI18n();
const route = useRoute();
const shouldShowNavbar = computed(() => !route.matched.some((m) => m.meta.hideNavBar === true));
const isRouteReady = ref(false);
const router = useRouter();

onMounted(() => {
  router.isReady().then(() => {
    isRouteReady.value = true;
  });
});

const updateHtmlLang = () => {
  document.documentElement.lang = locale.value;
};

onMounted(updateHtmlLang);
watch(locale, updateHtmlLang);
</script>
